import { useState } from "react"
import { motion } from "framer-motion"
import { addPropertyControls, ControlType } from "framer"

export default function Download(props) {
    const { title, tint, style, file } = props

    const handleDownload = () => {
        const pdfViewerUrl = `https://docs.google.com/viewer?url=${encodeURIComponent(
            file
        )}&embedded=true`
        window.open(pdfViewerUrl, "_blank")
    }

    return (
        <motion.div style={{ ...style, ...containerStyle }}>
            <motion.div
                onTap={handleDownload}
                style={{
                    margin: 0,
                    padding: 8,
                    borderRadius: 8,
                    backgroundColor: tint,
                    color: "#2e2e2e",
                    fontFamily: "Montserrat",
                    fontSize: 18,
                }}
                whileHover={{ fontWeight: "bold" }}
            >
                {title}
            </motion.div>
        </motion.div>
    )
}

Download.defaultProps = {
    tint: "#FAFAFA",
    title: "Download",
}

addPropertyControls(Download, {
    tint: {
        title: "Tint",
        type: ControlType.Color,
    },
    title: {
        title: "Label",
        type: ControlType.String,
    },
    file: {
        title: "File",
        type: ControlType.File,
        allowedFileTypes: [],
    },
})

const containerStyle = {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    overflow: "hidden",
}
